<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Configurações</h3>
        <div>Gerencie suas informações da conta digital, privacidade e segurança.</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-md-6">
                <p class="mb-0"><strong>CNPJ</strong></p>
                <p class="mb-3 notranslate">{{ account.user.person_number }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-0"><strong>Razão Social</strong></p>
                <p class="mb-3 notranslate">{{ account.user.name }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-0"><strong>Pessoa sócia administradora</strong></p>
                <p class="mb-3 notranslate">{{ account.user.responsible }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-0"><strong>CPF pessoa sócia administradora</strong></p>
                <p class="mb-3 notranslate">{{ account.user.cpf }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0 bg-soft-default">
          <div class="card-body">
            <strong>Alteração dos dados:</strong><br>
            Por questões de segurança, entre em contato para solicitar alteração das informações.
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>